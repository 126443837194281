<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="grid"
        title="슬러지의 발생량 및 처리량"
        tableId="grid"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="grid.data"
        :usePaging="false"
        :hideBottom="false"
        :columnSetting="false"
        :filtering="false"
        :isExcelDown="false"
        :editable="editable"
        :gridHeightAuto="param.isFullScreen"
        @table-data-change="tableDataChange"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <!-- <c-btn v-if="!param.isFullScreen && editable" label="추가" icon="add" @btnClicked="addRow" />
            <c-btn v-if="!param.isFullScreen && editable" label="삭제" icon="remove" @btnClicked="deleteRow" /> -->
            <c-btn label="저장" icon="save" v-if="!param.isFullScreen && editable" @btnClicked="saveData" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'daily-report-04',
  props: {
    param: {
      type: Object,
      default: () => ({
        plantCd: null,
        envAirDailyResultId: '',
        isFullScreen: false,
        dailyResultDt: '',
        approvalStatusCd: null,
        approvalTarget: false,
      }),
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'preAmount',
            field: 'preAmount',
            label: '전일보관량(㎥)',
            align: 'center',
            type: 'number',
            style: 'width: 15%',
            sortable: false,
          },
          {
            name: 'genAmount',
            field: 'genAmount',
            label: '슬러지발생량(㎥)',
            align: 'center',
            type: 'number',
            style: 'width: 15%',
            sortable: false,
          },
          {
            name: 'proAmount',
            field: 'proAmount',
            label: '처리량(㎥)',
            align: 'center',
            type: 'number',
            style: 'width: 15%',
            sortable: false,
          },
          {
            name: 'stoAmount',
            field: 'stoAmount',
            label: '보관량(㎥)',
            align: 'center',
            type: 'number',
            style: 'width: 15%',
            sortable: false,
          },
          {
            name: 'stoPercent',
            field: 'stoPercent',
            label: '함수율(%)',
            align: 'center',
            type: 'number',
            style: 'width: 15%',
            sortable: false,
          },
          {
            name: 'stoLocation',
            field: 'stoLocation',
            label: '보관장소',
            align: 'center',
            type: 'text',
            sortable: false,
          },
        ],
        data: [],
        height: '600px',
      },
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  computed: {
    editable() {
      if (this.param.approvalTarget) {
        return true;
      } else if (this.param.approvalStatusCd !== null) {
        return false;
      } else {
        return this.$route.meta.editable
      }
    },
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.listUrl = selectConfig.env.water.daily.sludge.list.url;
      this.saveUrl = transactionConfig.env.water.daily.sludge.save.url;
      this.deleteUrl = transactionConfig.env.water.daily.sludge.delete.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {envWaterDailyResultId: this.param.envWaterDailyResultId, plantCd: this.param.plantCd};
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    addRow() {
      this.grid.data.push(
        {
          envWaterDailyResultId: this.param.envWaterDailyResultId,  // 수질일지 일련번호
          envWaterMstSludgeId: uid(),  // 수질 발생량및처리량 일련번호
          genAmount: '',  // 슬러지발생량
          proAmount: '',  // 처리량
          stoAmount: '',  // 보관량
          stoPercent: '',  // 함수율
          stoLocation: '',  // 보관장소
          editFlag: 'C',
        }
      )
    },
    deleteRow() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '선택된 항목이 없습니다.', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['grid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveData() {
      let saveData = this.grid.data.filter( x => {
        return x.editFlag == 'U' || x.editFlag == 'C'
      });
      this.$_.forEach(saveData, item => {
        item.regUserId = this.$store.getters.user.userId;
        item.chgUserId = this.$store.getters.user.userId;
      });
      if(saveData.length > 0) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?', // 저장하시겠습니까?
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.saveUrl;
            this.$http.type = 'POST';
            this.$http.param = saveData;
            this.$http.request(() => {
              this.getList();
              window.getApp.$emit('ALERT', {
                title: '안내', /* 안내 */
                message: '저장되었습니다.', /* 저장되었습니다. */
                type: 'success', // success / info / warning / error
              });
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } else {
        window.getApp.$emit('ALERT', {
          title: '안내', /* 안내 */
          message: '변경된 데이터가 없습니다.', // 변경된 데이터가 없습니다., /* 변경된 데이터가 없습니다. */
          type: 'info', // success / info / warning / error
        });
      }
    },
    tableDataChange(props) {
      if (props.row['preAmount'] > 0) {
        this.$set(props.row, 'stoAmount', (props.row['preAmount'] | 0) + (props.row['genAmount'] | 0) - (props.row['proAmount'] | 0));
      } else if (props.row['preAmount'] == 0 || props.row['preAmount'] === null) {
        this.$set(props.row, 'stoAmount', (props.row['genAmount'] | 0) - (props.row['proAmount'] | 0));
      }
    },
  }
};
</script>
